import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(customer_id) {
		const params = {
			order: 'desc',
			order_by: 'name',
			offset: 0,
			limit: 50,
		}
		const url = API_ROUTES.brand.get.replace(':customer_id', customer_id)
		const { data } = await useJwt.get(url, params)

		return data
	},
	async find(customer_id, id) {
		const url = API_ROUTES.brand.find.replace(':customer_id', customer_id).replace(':id', id)
		const { data } = await useJwt.get(url)

		return data
	},
	async save(customer_id, id, data) {
		const url = API_ROUTES.brand.save
			.replace(':customer_id', customer_id)
			.replace(':id', id)
		return useJwt.put(url, data)
	},
	async delete(customer_id, id) {
		const url = API_ROUTES.brand.save
			.replace(':customer_id', customer_id)
			.replace(':id', id)
		return useJwt.delete(url)
	},
	async update(id, data) {
		return useJwt.update(`${API_ROUTES.brand.update}/${id}`, data)
	},
}
